/**
 * This utility is useful to be able to check that you've handled all cases in a switch or if/else.
 * For switch, add a default case that calls assertUnreachable and it will fail if you are not handling all cases.
 * View an example here: https://tinyurl.com/2p9xmk5r
 *
 */
export default function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

/**
 * Similar to the above, but does not throw.
 * Useful for things like metafields where we may not have every case covered yet.
 */
export function assumeUnreachable(_: never) {}
